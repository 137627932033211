import { ThemeOptions } from "@mui/material";
import { createBoilerplateTheme } from "@gisce/oficina-virtual-components";

import logo from '/images/logo.png'
import logoBar from '/images/logoBar.png'

const customConstants: ThemeOptions = {
        palette: {
            primary: {
		main: "#6e96d8"
	    },
            secondary: {
		main: "#98948d"
	    }
	},
	custom: {
    constants: {
      logoImage: logo,
      logoBarImage: logoBar,
		}
	}
};

export default createBoilerplateTheme(customConstants);
