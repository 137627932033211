import {
    Avatar,
    CircularProgress,
    ListItem,
    ListItemAvatar,
    ListItemText,
    useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * UI component
 *
 * Responsible for rendering the provided percentage data.
 */
const VirtualBatteryPercentageCard = ({ percentageData, active, style }) => {
    const { t } = useTranslation();
    style = style ?? {};
    const theme = useTheme();

    console.log("theme.palette.type", theme.palette.type);
    return (
        <>
            {percentageData && (
                <ListItem style={{ width: "50%", ...style }} selected={active}>
                    <ListItemAvatar>
                        <Avatar>
                            <CircularProgress
                                style={{
                                    backgroundColor: theme.palette.grey[200],
                                }}
                                variant="determinate"
                                value={parseFloat(percentageData.percentage)}
                            />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${t("common:text.bateria_virtual_contract")}: ${percentageData.polissa_name
                            }`}
                        secondary={`${percentageData.percentage}%`}
                    />
                </ListItem>
            )}
        </>
    );
};

export default VirtualBatteryPercentageCard;
