import Settings from "../settings";
import {
  data_fetch_api_resource,
  data_create_api_resource,
} from "../utils/http_functions";
import { parseJSON } from "../utils/misc";
import {
  SUBMIT_ABOUT_INSTALLATION_DATA,
  STORE_SIPS_DATA,
  STORE_SIPS_GAS_DATA,
  STORE_CUPS_DATA,
  STORE_RESIDENCE_TYPE_DATA,
  STORE_UPLOADED_INVOICES_DATA,
  SUBMIT_ABOUT_YOU_DATA,
  SET_ACTIVE_CONTRACT,
  SET_CUPS_VALID,
  SET_CUPS_INVALID,
  SUBMIT_ABOUT_CONTRACT_DATA,
  SUBMIT_ABOUT_GAS_CONTRACT_DATA,
  SUBMIT_PAYMENT_DATA,
  SUBMIT_CONFIRM_DATA,
  NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST,
  NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE,
  FETCH_CONTRACTATION_CONFIG_REQUEST,
  RECEIVE_CONTRACTATION_CONFIG,
  SET_LEAD_ID,
  SET_LEAD_STEP_CURRENT,
  SET_LEAD_INDEX_COMPLETED,
  SET_LEAD_ERRORS,
  SET_LEAD_SUBMIT_REQUEST,
} from "../constants";
import queryString from "query-string";
import * as Sentry from "@sentry/react";
import { i18n } from "../config";

// ----------------------------------------------------------------------------
// Electricity Contractation Form
// ----------------------------------------------------------------------------
export const setLeadSubmitting = () => {
  return {
    type: SET_LEAD_SUBMIT_REQUEST
  }
}

export const setLeadId = (leadId) => {
  return {
    type: SET_LEAD_ID,
    payload: {"leadId": leadId}
  }
}

export const setLeadStepCompleted = (stepIndex) => {
  return {
    type: SET_LEAD_INDEX_COMPLETED,
    payload: {
      stepIndex
    }
  }
}

export const setLeadCurrentStep = ({index, name}) => {
  return {
    type: SET_LEAD_STEP_CURRENT,
    payload: { index, name }
  }
}

export const sendElectricityLeads = (data, {checkSolvencia, checkDNI, nextStep, currentStep, gasData}) => {
  return async (dispatch) => {
    dispatch(setLeadSubmitting());
    const response = await data_create_api_resource(null, "leads", {
      "FormConfirm": data
    }).catch((e) => {
      Sentry.captureException(e);
    });

    const newLeadErrors = { badScore: null, dniInvalid: null, genericError: null };
    if (response?.status === 200 && response.data?.lead_id) {
      dispatch(setLeadId(response.data.lead_id));

      if (gasData) {
        gasData.electricityLeadId = response.data.lead_id;
        dispatch(sendGasLeads({
          "FormConfirm": gasData
        }));
      }

      // CHECKING LEAD ERRORS
      if (checkSolvencia) Object.assign(newLeadErrors, await checkLeadScore(null, response.data.lead_id));
      if (checkDNI) Object.assign(newLeadErrors, await checkLeadDNI(null, response.data.lead_id));
      dispatch(setLeadsErrors(newLeadErrors));
      if ((!!nextStep.index && !!nextStep.name) && (!newLeadErrors.badScore && !newLeadErrors.dniInvalid)) {
        dispatch(setLeadStepCompleted(currentStep));
        dispatch(setLeadCurrentStep(nextStep));
      }
    } else {
      newLeadErrors["genericError"] = true
      dispatch(setLeadsErrors(newLeadErrors));
    }
  };
};

export const sendGasLeads = (data) => {
  return async (dispatch) => {
    await data_create_api_resource(null, "gas/leads", data, 2).catch((e) => {
      Sentry.captureException(e);
    });
  }
};

export const setLeadsErrors = (data) => {
  return {
    type: SET_LEAD_ERRORS,
    payload: data
  };
};

export const resetLeadsErrors = () => {
  return async (dispatch) => {
    dispatch(setLeadsErrors({
      badScore: null,
      dniInvalid: null,
    }))
  }
};

export const checkLeadScore = async (token, lead_id) => {
  const response = await data_fetch_api_resource(token, `leads/solvencia/${lead_id}`)
    .catch((e) => {
      Sentry.captureException(e);
    });
  if (response?.status === 200) {
    return {badScore: !response.data['scoring']};
  } 
  return {badScore: true};
};

export const checkLeadDNI = async (token, lead_id) => {
  const response = await data_fetch_api_resource(token, `leads/dni-validation/${lead_id}`)
    .catch((e) => {
      Sentry.captureException(e);
    });
  if (response?.status === 200) {
    return {dniInvalid: !response.data['dni_valid']};
  } 
  return {dniInvalid: true};
};

export const setAboutInstallationData = (data) => {
  
  let { leadsConsent, cups, address, residenceType, cnae, invoice, comerOrigin, activeContract, acceptMorosityVerification} = data;
  if (residenceType || cnae === null) {
    cnae = { 
      id: 986, 
      name: "9820", 
      descripcio: "9820: Actividades de los hogares como productores de servicios para uso propio" 
    }
  }
  return {
    type: SUBMIT_ABOUT_INSTALLATION_DATA,
    payload: {
      leadsConsent,
      cups,
      address,
      residenceType,
      cnae,
      invoice,
      comerOrigin,
      activeContract,
      acceptMorosityVerification
    },
  };
}

export const setAboutYouData = (data) => {
  return {
    type: SUBMIT_ABOUT_YOU_DATA,
    payload: data,
  };
}

export const setActiveContract = (value) => {
  return {
    type: SET_ACTIVE_CONTRACT,
    payload: {
      activeContract: value,
    }
  };
};

export const updateResidenceType = (value) => {
  return {
    type: STORE_RESIDENCE_TYPE_DATA,
    payload: {
      residenceType: value
    }
  }
};

export const updateUploadedFiles = (value) => {
  return {
    type: STORE_UPLOADED_INVOICES_DATA,
    payload: {
      invoice: value
    }
  }
};

export const setAboutContractData = ({
  power,
  power2,
  power3,
  power4,
  power5,
  power6,
  accessTariff,
  selectedProduct,
  selfcons,
  selfconsAttachments,
  promotionalCode
}) => {
  return {
    type: SUBMIT_ABOUT_CONTRACT_DATA,
    payload: {
      power,
      power2,
      power3,
      power4,
      power5,
      power6,
      accessTariff,
      selectedProduct,
      selfcons,
      selfconsAttachments,
      promotionalCode
    },
  };
};

export const submitAboutGasContractData = ({
  cupsGas,
  comerGasOrigin,
  selectedGasProduct,
}) => {
  return {
    type: SUBMIT_ABOUT_GAS_CONTRACT_DATA,
    payload: {
      cupsGas,
      comerGasOrigin,
      selectedGasProduct,
    },
  };
};

export const setPaymentData = ({ iban, isOwner }) => {
  return {
    type: SUBMIT_PAYMENT_DATA,
    payload: {
      iban,
      isOwner,
    },
  };
};

export const setConfirmData = ({
  promotionalCode,
  acceptOVConditions,
  acceptGdpr,
}) => {
  return {
    type: SUBMIT_CONFIRM_DATA,
    payload: {
      promotionalCode,
      acceptOVConditions,
      acceptGdpr,
    },
  };
};

// ----------------------------------------------------------------------------
// Contractation config (prefilled fields)
// ----------------------------------------------------------------------------

export const fetchContractationConfigRequest = () => {
  return {
    type: FETCH_CONTRACTATION_CONFIG_REQUEST,
  };
};

export const receiveContractationConfig = (response) => {
  return {
    type: RECEIVE_CONTRACTATION_CONFIG,
    payload: {
      contractationConfig: response,
    },
  };
};

export const fetchContractationConfig = () => {
  return async (dispatch) => {
    dispatch(fetchContractationConfigRequest());
    try {
      const json = await data_fetch_api_resource(null, `contractacio/config`);
      const response = await parseJSON(json);
      dispatch(receiveContractationConfig(response));
    } catch (e) {
      Sentry.captureException(e);
      if (
        Settings.electricityContractation &&
        Settings.newContract.authenticate &&
        e.status === 401
      ) {
        // dispatch(logoutAndRedirect(e));
      } else {
        dispatch(receiveContractationConfig(null));
      }
    }
  };
};

// ----------------------------------------------------------------------------
// CUPS Information
// ----------------------------------------------------------------------------

export const storeCUPSData = (data) => {
  return {
    type: STORE_CUPS_DATA,
    payload: {
      cups: data
    }
  }
}

export const setCupsAvailability = (type, data) => {
  return {
    type: type,
    payload: {
      ...data
    },
  };
}

export const validateCUPSAvailability = (values, keepSIPSData = false) => {
  return async (dispatch) => {
    await data_fetch_api_resource(
      null,
      `contractacio/config/cups/${values.cups}`
    ).then((result) => {
      let response = null;
      if (result && result.status === 200) {
        if (!result.data?.cups_availability?.available ?? false) {
          dispatch(
            setCupsAvailability(SET_CUPS_INVALID, {
              invalid: true,
              reason: result.data?.cups_availability?.reason ?? 'Invalid CUPS'
            })
          );
        } else {
          dispatch(setCupsAvailability(SET_CUPS_VALID, {invalid: null, reason: ''}));
          if(keepSIPSData){
            dispatch(storeSIPSData({
              ...result.data,
            }));
          }
        }
      } else {
        response = i18n.t('common:text.cannot_validate_async');
      }

      return (response && { cups: response }) || null;
    })
  }
};

export const storeSIPSData = (data) => {
  return {
    type: STORE_SIPS_DATA,
    payload: {
      sipsData: data,
    },
  };
};

export const confirmNewContractRequest = () => {
  return {
    type: NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST,
  };
};

export const receiveConfirmNewContractResponse = (response) => {
  return {
    type: NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE,
    payload: {
      confirmResponse: response,
    },
  };
};

export const confirmNewContract = (postData, {currentStep, nextStep}) => {
  return async (dispatch) => {
    dispatch(confirmNewContractRequest());
    try {
      const json = await data_create_api_resource(
        null,
        "leads",
        postData
      );
      const response = await parseJSON(json);
      dispatch(receiveConfirmNewContractResponse(response));
      if (response.status === "OK") {
        dispatch(setLeadStepCompleted(currentStep));
        dispatch(setLeadCurrentStep(nextStep));
      }
    } catch (e) {
      Sentry.captureException(e);
      if (
        Settings.electricityContractation &&
        Settings.newContract.authenticate &&
        e.status === 401
      ) {
        // dispatch(logoutAndRedirect(e));
      } else {
        dispatch(receiveConfirmNewContractResponse({
          result: {
            error: true,
            render: {
              format: 'markdown',
              body: i18n.t('common:text.contractation_connectivity_error')
            }
          }
        }));
      }
    }      
  };
};
