import {debug} from './debug';
import {version, version_pr} from './version';

export const regional_settings = {'currency': "€",'energy_unit': "kWh",'potence_unit': "kW",'amount_unit': "€"}

export const DEBUG = (debug)?debug:false;

export const VERSION = version;
export const VERSION_PR = version_pr;

export const API_SPECIFICATION = 1;
export const API_PREFIX = "/api/v" + API_SPECIFICATION;

export const FETCH_VERSION_REQUEST = 'FETCH_VERSION_REQUEST';
export const RECEIVE_VERSION = 'RECEIVE_VERSION';
export const RECEIVE_VERSION_ERROR = 'RECEIVE_VERSION_ERROR';

export const LOGIN_CREDENTIALS_SUCCESS = 'LOGIN_CREDENTIALS_SUCCESS';
export const LOGIN_CREDENTIALS_FAILURE = 'LOGIN_CREDENTIALS_FAILURE';
export const LOGIN_CREDENTIALS_REQUEST = 'LOGIN_CREDENTIALS_REQUEST';
export const LOGIN_NOT_MADE = 'LOGIN_NOT_MADE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const LOGIN_REFRESH_REQUEST = 'LOGIN_REFRESH_REQUEST';
export const LOGIN_REFRESH_SUCCESS = 'LOGIN_REFRESH_SUCCESS';
export const LOGIN_REFRESH_FAILURE = 'LOGIN_REFRESH_FAILURE';

export const LOGIN_TOKEN_REQUEST = 'LOGIN_TOKEN_REQUEST';
export const LOGIN_TOKEN_SUCCESS = 'LOGIN_TOKEN_SUCCESS';
export const LOGIN_TOKEN_FAILURE = 'LOGIN_TOKEN_FAILURE';

export const LOGIN_OPENID_REQUEST = 'LOGIN_OPENID_REQUEST';
export const LOGIN_OPENID_SUCCESS = 'LOGIN_OPENID_SUCCESS';
export const LOGIN_OPENID_FAILURE = 'LOGIN_OPENID_FAILURE';

export const LOGIN_CLIENTID_REQUEST = 'LOGIN_CLIENTID_REQUEST';
export const LOGIN_CLIENTID_SUCCESS = 'LOGIN_CLIENTID_SUCCESS';
export const LOGIN_CLIENTID_FAILURE = 'LOGIN_CLIENTID_FAILURE';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';

export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';

export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_REDIRECTED = 'SET_REDIRECTED'; 

export const FETCH_PROTECTED_DATA_REQUEST = 'FETCH_PROTECTED_DATA_REQUEST';
export const RECEIVE_PROTECTED_DATA = 'RECEIVE_PROTECTED_DATA';

export const RECOVER_USER_REQUEST = 'RECOVER_USER_REQUEST';
export const RECOVER_USER_SUCCESS = 'RECOVER_USER_SUCCESS';
export const RECOVER_USER_FAILURE = 'RECOVER_USER_FAILURE';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const RECEIVE_PROFILE = 'RECEIVE_PROFILE';
export const RECEIVE_PROFILE_KO = 'RECEIVE_PROFILE_KO';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_OK = 'UPDATE_PROFILE_OK';
export const UPDATE_PROFILE_KO = 'UPDATE_PROFILE_KO';

export const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST';
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS';
export const RECEIVE_SETTINGS_KO = 'RECEIVE_SETTINGS_KO';

export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_OK = 'UPDATE_SETTINGS_OK';
export const UPDATE_SETTINGS_KO = 'UPDATE_SETTINGS_KO';


export const APP_REMOVE_NODE = 'APP_REMOVE_NODE';
export const APP_TOGGLE_NAME = 'APP_TOGGLE_NAME';
export const APP_UPDATE_PATHS = 'APP_UPDATE_PATHS';
export const APP_CHANGE_OFFSET = 'APP_CHANGE_OFFSET';


export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const NOTIFICATION_DONE = 'NOTIFICATION_DONE';


export const SET_CONTRACT_ITEMS = 'SET_CONTRACT_ITEMS';
export const RECEIVE_CONTRACTS = 'RECEIVE_CONTRACTS';
export const RECEIVE_CONTRACTS_GAS = 'RECEIVE_CONTRACTS_GAS';
export const FETCH_CONTRACTS_REQUEST = 'FETCH_CONTRACTS_REQUEST';
export const FETCH_CONTRACTS_REQUEST_GAS = 'FETCH_CONTRACTS_REQUEST_GAS';
export const FILTER_CONTRACTS_REQUEST = 'FILTER_CONTRACTS_REQUEST';
export const FILTER_CONTRACTS_REQUEST_GAS = 'FILTER_CONTRACTS_REQUEST_GAS';
export const CLEAR_CONTRACTS_REQUEST = 'CLEAR_CONTRACTS_REQUEST';
export const CLEAR_CONTRACTS_FILTER = 'CLEAR_CONTRACTS_FILTER';
export const SYNC_SINGLE_CONTRACT = 'SYNC_SINGLE_CONTRACT';


export const SET_BATERIA_PERCENTATGE_REQUEST = 'SET_BATERIA_PERCENTATGE_REQUEST';
export const SET_BATERIA_PERCENTATGE_DONE = 'SET_BATERIA_PERCENTATGE_DONE';
export const SET_BATERIA_PERCENTATGE_ERROR = 'SET_BATERIA_PERCENTATGE_ERROR';
export const SET_MOVE_BATERIA_REQUEST = 'SET_MOVE_BATERIA_REQUEST';
export const SET_MOVE_BATERIA_DONE = 'SET_MOVE_BATERIA_DONE';
export const SET_MOVE_BATERIA_ERROR = 'SET_MOVE_BATERIA_ERROR';
export const FETCHING_VIRTUAL_BATTERY_TYPES = 'FETCHING_VIRTUAL_BATTERY_TYPES';
export const RECEIVE_VIRTUAL_BATTERY_TYPES = 'RECEIVE_VIRTUAL_BATTERY_TYPES';
export const UPDATING_VIRTUAL_BATTERY_TYPES = 'UPDATING_VIRTUAL_BATTERY_TYPES';
export const UPDATED_VIRTUAL_BATTERY_TYPES = 'UPDATED_VIRTUAL_BATTERY_TYPES';
export const FETCH_BATERIA_VIRTUAL_PERCENTAGES = 'FETCH_BATERIA_VIRTUAL_PERCENTAGES';
export const RECEIVE_BATERIA_VIRTUAL_PERCENTAGES = 'RECEIVE_BATERIA_VIRTUAL_PERCENTAGES';
export const EXPORT_VIRTUAL_BATTERIES_REQUEST = 'EXPORT_VIRTUAL_BATTERIES_REQUEST';
export const EXPORT_VIRTUAL_BATTERIES_DONE = 'EXPORT_VIRTUAL_BATTERIES_DONE';
export const MESSAGE_VIRTUAL_BATTERIES = 'MESSAGE_VIRTUAL_BATTERIES';


export const FETCH_NEW_CONTRACT_PREFILL_REQUEST = 'FETCH_NEW_CONTRACT_PREFILL_REQUEST';
export const RECEIVE_NEW_CONTRACT_PREFILL = 'RECEIVE_NEW_CONTRACT_PREFILL';

export const RECEIVE_CONTRACT = 'RECEIVE_CONTRACT';
export const FETCH_CONTRACT_REQUEST = 'FETCH_CONTRACT_REQUEST';
export const EXPORT_CONTRACT_REQUEST = 'EXPORT_CONTRACT_REQUEST';
export const EXPORT_CONTRACT_REQUEST_GAS = 'EXPORT_CONTRACT_REQUEST_GAS';
export const MESSAGE_CONTRACT = 'MESSAGE_CONTRACT';
export const MESSAGE_CONTRACT_GAS = 'MESSAGE_CONTRACT_GAS';
export const EXPORT_CONTRACT_DONE = 'EXPORT_CONTRACT_DONE';
export const EXPORT_CONTRACT_DONE_GAS = 'EXPORT_CONTRACT_DONE_GAS';

export const RECEIVE_INVOICES = 'RECEIVE_INVOICES';
export const RECEIVE_INVOICES_FILTER = 'RECEIVE_INVOICES_FILTER';
export const RECEIVE_INVOICES_GAS = 'RECEIVE_INVOICES_GAS';
export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_FILTER_REQUEST = 'FETCH_INVOICES_FILTER_REQUEST';
export const FETCH_INVOICES_REQUEST_GAS = 'FETCH_INVOICES_REQUEST_GAS';
export const EXPORT_INVOICE_REQUEST = 'EXPORT_INVOICE_REQUEST';
export const EXPORT_INVOICE_REQUEST_GAS = 'EXPORT_INVOICE_REQUEST_GAS';

export const RECEIVE_INVOICES_MULTIPOINT = 'RECEIVE_INVOICES_MULTIPOINT';
export const RECEIVE_INVOICES_MULTIPOINT_FILTER = 'RECEIVE_INVOICES_MULTIPOINT_FILTER';
export const FETCH_INVOICES_MULTIPOINT_REQUEST = 'FETCH_INVOICES_MULTIPOINT_REQUEST';
export const FETCH_INVOICES_MULTIPOINT_FILTER_REQUEST = 'FETCH_INVOICES_MULTIPOINT_FILTER_REQUEST';
export const EXPORT_INVOICE_MULTIPOINT_REQUEST = 'EXPORT_INVOICE_MULTIPOINT_REQUEST';
export const EXPORT_INVOICE_MULTIPOINT_DONE = 'EXPORT_INVOICE_MULTIPOINT_DONE';
export const MESSAGE_INVOICE_MULTIPOINT = 'MESSAGE_INVOICE_MULTIPOINT';


export const MESSAGE_INVOICE = 'MESSAGE_INVOICE';
export const MESSAGE_INVOICE_GAS = 'MESSAGE_INVOICE_GAS';
export const EXPORT_INVOICE_DONE = 'EXPORT_INVOICE_DONE';
export const EXPORT_INVOICE_DONE_GAS = 'EXPORT_INVOICE_DONE_GAS';
export const CLEAR_INVOICE_PAGINATION = 'CLEAR_INVOICE_PAGINATION';
export const SET_ITEMS_OFFSET = 'SET_ITEMS_OFFSET';
export const SET_FILTERS = 'SET_FILTERS';

export const FETCH_CONTACT_REQUEST = 'FETCH_CONTACT_REQUEST';
export const RECEIVE_CONTACT = 'RECEIVE_CONTACT';
export const RECEIVE_CONTACT_ERROR = 'RECEIVE_CONTACT_ERROR';
export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_OK = 'CREATE_CONTACT_OK';
export const CREATE_CONTACT_KO = 'CREATE_CONTACT_KO';

export const SET_LEAD_ID = "SET_LEAD_ID";
export const SET_LEAD_STEP_CURRENT = "SET_LEAD_STEP_CURRENT";
export const SET_LEAD_INDEX_COMPLETED = "SET_LEAD_INDEX_COMPLETED";
export const SET_LEAD_ERRORS = "SET_LEAD_ERRORS";
export const SET_LEAD_SUBMIT_REQUEST = "SET_LEAD_SUBMIT_REQUEST";

export const SUBMIT_ABOUT_INSTALLATION_DATA = 'SUBMIT_ABOUT_INSTALLATION_DATA';
export const SUBMIT_ABOUT_YOU_DATA = 'SUBMIT_ABOUT_YOU_DATA';
export const SUBMIT_ABOUT_CONTRACT_DATA = 'SUBMIT_ABOUT_CONTRACT_DATA';
export const SUBMIT_ABOUT_GAS_CONTRACT_DATA = 'SUBMIT_ABOUT_GAS_CONTRACT_DATA';
export const SUBMIT_PAYMENT_DATA = 'SUBMIT_PAYMENT_DATA';
export const SUBMIT_CONFIRM_DATA = 'SUBMIT_CONFIRM_DATA';

export const STORE_SIPS_DATA = 'STORE_SIPS_DATA';
export const STORE_SIPS_GAS_DATA = 'STORE_SIPS_GAS_DATA';

export const REGISTER_COUPON = 'REGISTER_COUPON';

/* GAS */
export const SUBMIT_ABOUT_INSTALLATION_GAS_DATA = 'SUBMIT_ABOUT_INSTALLATION_GAS_DATA';
export const SUBMIT_ABOUT_YOU_GAS_DATA = 'SUBMIT_ABOUT_YOU_GAS_DATA';
export const SET_CUPS = 'SET_CUPS';
export const SET_INCLUDE_GAS = 'SET_INCLUDE_GAS';
export const SET_ACTIVE_CONTRACT = 'SET_ACTIVE_CONTRACT';
export const STORE_RESIDENCE_TYPE_DATA = 'STORE_RESIDENCE_TYPE_DATA ';
export const STORE_UPLOADED_INVOICES_DATA = 'STORE_UPLOADED_INVOICES_DATA ';
export const STORE_CUPS_DATA = 'STORE_CUPS_DATA';
export const STORE_GAS_CUPS_DATA = 'STORE_GAS_CUPS_DATA';
export const EMPTY_GAS_CUPS_DATA = 'EMPTY_GAS_CUPS_DATA';
export const SET_CUPS_VALID = 'SET_CUPS_VALID';
export const SET_GAS_CUPS_VALID = 'SET_GAS_CUPS_VALID';
export const SET_CUPS_INVALID = 'SET_CUPS_INVALID';
export const SET_GAS_CUPS_INVALID = 'SET_GAS_CUPS_INVALID';
export const SET_CHANGE_OWNER = 'SET_CHANGE_OWNER';
export const SET_GAS_COMER_ORIGIN = 'SET_GAS_COMER_ORIGIN';
export const SET_GAS_ACCESS_TARIFF = 'SET_GAS_ACCESS_TARIFF';
export const SET_GAS_INVOICE = 'SET_GAS_INVOICE';
export const SUBMIT_ABOUT_CONTRACT_GAS_DATA = 'SUBMIT_ABOUT_CONTRACT_GAS_DATA';
export const SUBMIT_PAYMENT_GAS_DATA = 'SUBMIT_PAYMENT_GAS_DATA';
export const SUBMIT_CONFIRM_GAS_DATA = 'SUBMIT_CONFIRM_GAS_DATA';
export const STORE_GAS_SIPS_DATA = 'STORE_GAS_SIPS_DATA';

export const FETCH_CONTRACTATION_CONFIG_REQUEST = 'FETCH_CONTRACTATION_CONFIG_REQUEST';
export const RECEIVE_CONTRACTATION_CONFIG = 'RECEIVE_CONTRACTATION_CONFIG';
export const FETCH_GAS_CONTRACTATION_CONFIG_REQUEST = 'FETCH_GAS_CONTRACTATION_CONFIG_REQUEST';
export const RECEIVE_GAS_CONTRACTATION_CONFIG = 'RECEIVE_GAS_CONTRACTATION_CONFIG';

/* GAS */
export const SELECT_GAS_PRODUCT = 'SELECT_GAS_PRODUCT';
export const FETCH_AVAILABLE_GAS_PRODUCTS_REQUEST = 'FETCH_AVAILABLE_GAS_PRODUCTS_REQUEST';
export const RECEIVE_AVAILABLE_GAS_PRODUCTS = 'RECEIVE_AVAILABLE_GAS_PRODUCTS';

export const NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST = 'NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST';
export const NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE = 'NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE';

/* GAS */
export const NEW_GAS_CONTRACT_CONFIRM_REQUEST = 'NEW_GAS_CONTRACT_CONFIRM_REQUEST';
export const NEW_GAS_CONTRACT_CONFIRM_RECEIVE = 'NEW_GAS_CONTRACT_CONFIRM_RECEIVE';

/* CRM */

export const FETCH_CONTRACT_LIST = 'FETCH_CONTRACT_LIST';
export const FETCH_CRM_CASE_LIST = 'FETCH_CRM_CASE_LIST';
export const FETCH_CRM_CASE_DETAIL = 'FETCH_CRM_CASE_DETAIL';
export const FETCH_CRM_CASE_SECTIONS = 'FETCH_CRM_CASE_SECTIONS';
export const FETCH_CRM_CASE_CATEGORIES = 'FETCH_CRM_CASE_CATEGORIES';
export const ADD_CRM_CASE_MESSAGE = 'ADD_CRM_CASE_MESSAGE';
export const OPEN_CRM_CASE_MESSAGE = 'OPEN_CRM_CASE_MESSAGE';

/* BANNERS */
export const RECIEVE_BANNERS = 'RECIEVE_BANNERS';
export const FETCH_BANNERS = 'FETCH_BANNERS';
export const SET_BANNERS_ERRORS = 'SET_BANNERS_ERRORS';

export const colors = [
    '#61A2A5',
    '#D5D5D3',
    '#2E5677',
    '#ffa726',
    '#ff7043',
    '#8d6e63',
    '#bdbdbd',
    '#78909c',
    '#ef5350',
    '#ec407a',
    '#ab47bc',
    '#7e57c2',
    '#5c6bc0',
    '#42a5f5',
    '#29b6f6',
    '#26c6da',
    '#26a69a',
];


export const colors_nova = [
    '#66bb6a',
    '#9ccc65',
    '#d4e157',
    '#ffa726',
    '#ffcc28',
    '#ffee58',
    '#ec407a',
    '#ef5350',
    '#ff7043',
    '#ab47bc',
    '#7e57c2',
    '#5c6bc0',
    '#42a5f5',
    '#29b6f6',
    '#26c6da',
    '#26a69a',
    '#bdbdbd',
    '#78909c',
    '#8d6e63',
];
