import { SelectCardGroup, SelectCard } from "@gisce/oficina-virtual-components";

import { LoadingAnimation } from '../LoadingAnimation';
import { RichContent } from "@gisce/oficina-virtual-components";
import Settings from "../../settings";
import { FC, useEffect, useState } from "react";
import { Product } from "@/types/models/services/products";
import { default as productsService } from "@/services/products";
import useI18n from "@/hooks/useI18n";
import { useTranslation } from "react-i18next";
import { Alert, Box } from "@mui/material";


interface IElectricityProductsSelectProps {
  power: number;
  accessTariff: number;
  selfcons: boolean;
  cups: string;
  promoCodes?: string[];
  input: {
    onChange: (...event: any[]) => void;
    value: number;
  }
}

const ElectricityProductSelect: FC<IElectricityProductsSelectProps> = ({
  power,
  accessTariff,
  selfcons,
  cups,
  promoCodes,
  input
}) => {
  const { t } = useTranslation();
  const { currentLang } = useI18n();

  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchAvailableProducts = async () => {
    setIsFetching(true);
    productsService.fetchAvailableProducts({
      power, accessTariff, selfcons, cups, promoCodes
    }, {params: {lang: currentLang.value}}
    ).then(res => {
      setAvailableProducts(res);
    }).finally(() => {
      setIsFetching(false);
    })
  };

  useEffect(() => {
    fetchAvailableProducts();
  }, [power, accessTariff, promoCodes]);

  return isFetching ? (
    <LoadingAnimation />
  ) : (
    <>
      <SelectCardGroup 
        container 
        spacing={3}
        onChange={input.onChange} 
        value={input.value}
      >
        {availableProducts.map(
          product => <SelectCard
            xs={12}
            md={6}
            key={product.id}
            value={product.id}
            name={product.name}
            description={product.ov_description}
          />
        )}
      </SelectCardGroup>
      { power && accessTariff && availableProducts.length === 0 && (
        <Alert severity="warning" sx={{mt: 3}}>
          <RichContent
            content={t('common:text.contractation_no_available_tariffs',
              {
                phone: Settings.organization.phone_crm,
                phoneLink: Settings.organization.phone_crm.replace(/\s/g, '')
              }
            )}
          />         
        </Alert>
      )}
    </>
  );
}

export default ElectricityProductSelect;